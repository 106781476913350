<template>
  <div>
    <form
      @submit.prevent="submitHandler"
      @keyup.enter="handleKeyPress"
      v-if="isLoading == false"
    >
      <div class="p-fluid formgrid grid">
        <div class="p-fluid formgrid grid justify-content-around">
          <div class="field col-6">
            <h2>
              <template v-if="uid">Edit</template><template v-else>Add</template> Task Type
            </h2>
          </div>
          <div class="field col-6">
            <div class="p-inputgroup flex justify-content-end">
              <label class="mr-3 mt-1 hidden sm:block font-light">Active</label>
              <AInputSwitch
                v-model="task_type.active"
                class="green"
              />
            </div>
          </div>
          <Divider class="m-2 mb-5"></Divider>
          <div class="field col-12 sm:col-6">
            <label for="name">Task Type Name</label>

            <div class="p-inputgroup">
              <InputText
                placeholder="Task Type Name"
                v-model="task_type.type_name"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.task_type.type_name.required"
            >
              Task type name is required
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="name">Assigned Department</label>
            <div class="p-inputgroup">
              <Dropdown
                :options="departments"
                display="chip"
                v-model="selectedDepartment"
                optionLabel="department_name"
                @change="showGlCodes"
                placeholder="Select Department"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="name">Default estimated time</label>
            <i :class="'pl-2 las la-exclamation-circle cursor-pointer'" />
            <div class="p-inputgroup">
              <InputMask
                v-model="task_type.default_minutes"
                mask="99:99"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <div class="sm:mt-4 p-inputgroup flex align-items-center justify-content-center sm:justify-content-start">
              <Checkbox
                v-if="!isLoading"
                id="binary"
                v-model="task_type.billable"
                :binary="true"
                :trueValue="1"
                :falseValue="0"
              />
              <Skeleton
                v-else
                width="2rem"
                height="2rem"
                borderRadius="6px"
              ></Skeleton>
              <label
                v-if="!isLoading"
                class="font-light ml-3"
              >Billable</label>
              <Skeleton
                v-else
                class="ml-1 mt-1"
                width="30%"
                borderRadius="6px"
              ></Skeleton>
            </div>
          </div>
          <div class="field col-12 sm:col-6"></div>
          <!-- <div class="field col-12 sm:col-6">
            <label for="name">{{
              task_type.pricing_structure
                ? getName(task_type.pricing_structure)
                : ""
            }}</label>
            <div class="p-inputgroup flex align-items-center">
              <InputText v-model="price" mask="99-99" />
              <div
                v-if="
                  task_type.pricing_structure &&
                  task_type.pricing_structure.name == 'Rate per hour'
                "
                class="ml-3"
              >
                /hour
              </div>
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted"
            >
              Default rate is required
            </div>
          </div> -->
          <div class="field col-12 mt-3">
            <label>Billing Tiers</label>
            <div
              v-for="b in task_type.billing_tiers"
              :key="b.uid"
            >
              <div
                v-if="b.billing_tier_name !== null"
                class="flex mt-3"
              >
                <div
                  style="padding: 0.75rem 0rem"
                  class="col-6"
                >
                  {{ b.billing_tier_name ? b.billing_tier_name : b.name }}
                </div>
                <div class="col-6">
                  <InputNumber
                    :placeHolder="
                      b.billing_tier_name ? b.billing_tier_name : b.name
                    "
                    mode="decimal"
                    :minFractionDigits="2"
                    class="w-full"
                    v-model="b.price"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="submitted && hasEmptyBillingTier"
              class="error-validation-text pt-1"
            >
              All billing tiers are required
            </div>
          </div>

          <div class="field col-12 mt-3">
            <label for="name">Default Description</label>
            <Textarea
              ref="editor"
              v-model="task_type.description"
              :autoResize="true"
            ></Textarea>
            <!-- <Editor
              ref="editor"
              id="Description"
              placeholder="Default Description"
              :autoResize="true"
              v-model="task_type.description"
              rows="5"
            >
              <template #toolbar>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button><button class="ql-strike"></button>
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-align"></button>
                  <button class="ql-link"></button>
                </span> </template
            ></Editor> -->
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.task_type.description.required"
            >
              Default description is required
            </div>
          </div>

          <!-- <div class="field col-12 sm:col-6">
            <label for="name">GL Sales Code</label>
            <div class="p-inputgroup">
              <InputText v-model="task_type.gl_sales_code" />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.task_type.gl_sales_code.required"
            >
              GL sales code is required
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="name">GL Cost of Sales Code</label>
            <div class="p-inputgroup">
              <InputText v-model="task_type.gl_cost_of_sales_code" />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.task_type.gl_cost_of_sales_code.required"
            >
              Gl cost of sales is required
            </div>
          </div> -->
        </div>
      </div>

      <div class="flex justify-content-between pt-5">
        <Button type="submit"> SAVE </Button>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>

    <RocketLoad
      :full="true"
      v-else
    />
  </div>
</template>
<script>
import Vue from "vue";
import {
  state,
  // fetchTaskTypePricing,
  fetchBillingTiers,
  fetchDepartments,
  fetchTaskTypes,
  fetchTaskType,
} from "../../../services/data_service.js";
import { required } from "vuelidate/lib/validators";
export default {
  name: "UserSliderDetails",
  components: {},
  data() {
    return {
      submitted: false,
      selectedDepartment: null,
      formOptions: [],
      myFiles: [],
      taskTypePricingOptions: null,
      billingTierOptions: null,
      name: "",
      checked: true,
      isLoading: null,
      loadingText: "Loading...",
      inputIsVisible: false,
      task_type: {
        billable: "0",
        billing_tiers: null,
        departments: [],
        pricing_structure: null,
      },
    };
  },
  validations: {
    task_type: {
      type_name: {
        required,
      },
      description: {
        required,
      },
      billing_tiers: {
        $each: {
          price: {
            required,
          },
        },
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.loadData();
    this.pricingStructureOptions();

    if (this.uid) {
      this.task_type = state.taskType;
    }

    if (this.uid) {
      if (this.task_type.departments?.length > 0) {
        this.task_type.departments.map((department) => {
          if (department && department.uid) {
            this.selectedDepartment = this.departments.find(
              (a) => a.uid === department.uid
            );
          }
        });
      }
      this.task_type.default_minutes = this.convertMinsToHrsMins(
        this.task_type.default_minutes
      );
    } else {
      this.task_type.billing_tiers = this.billingTiers.filter(
        (a) => a.active === 1
      );
      // const billingTierOptions = this.task_type.billing_tiers.slice(1);
      // this.billingTierOptions = billingTierOptions;
    }

    if (this.task_type.pricing_structure == null) {
      this.task_type.pricing_structure = this.taskTypePricing.find(
        (value) => value.name === "Rate per hour"
      );
    }
    if (this.task_type.default_minutes == null) {
      this.task_type.default_minutes = "00:30";
    }

    this.isLoading = false;
  },
  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    async loadData() {
      var arr = [
        fetchBillingTiers(),
        // fetchTaskTypePricing(),
        fetchDepartments(),
      ];

      if (this.uid) {
        arr.push(fetchTaskType(this.uid));
      }

      await Promise.all(arr);
    },
    getName(t) {
      switch (t.name) {
        case "Rate per hour":
          return "Default task rate";
        case "Fixed price":
          return "Default task price";
        case "Percentage of total":
          return "Default %";
      }
    },
    showGlCodes() {
      if (this.selectedDepartment !== null) {
        this.task_type.gl_sales_code = this.selectedDepartment.gl_sales;
        this.task_type.gl_cost_of_sales_code =
          this.selectedDepartment.gl_cost_of_sales;
        this.task_type.billing_tiers?.forEach((b) => {
          if (b.name == "Default Task Rate") {
            b.price = this.selectedDepartment.rate;
          }
        });
      }
    },
    pricingStructureOptions() {
      const array = [];
      this.taskTypePricing.forEach((t) => {
        if (t.category === "task") {
          array.push({
            name: t.name,
            uid: t.uid,
          });
        }
        this.taskTypePricingOptions = array;
      });
    },
    submitHandler() {
      this.submitted = true;
      this.task_type.billing_tiers?.forEach((b) => {
        if (b.name === "Default Task Rate") {
          this.task_type.price = b.price;
        }
      });
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        var a = this.task_type.default_minutes.split(":");
        this.task_type.default_minutes = +a[0] * 60 + +a[1];
        this.task_type.is_template = 1;
        if (this.selectedDepartment) {
          this.task_type.departments?.splice(
            0,
            this.task_type.departments.length
          );
          this.task_type.departments?.push(this.selectedDepartment);
        }
        this.isLoading = true;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/tasktype/" +
              (!this.uid ? "create" : this.uid || ""),
            JSON.stringify(this.task_type),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.clearSliderContent();
            this.$toast.add({
              severity: "success",
              summary: "Task Type Saved",
              life: 3000,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
    handelMultiChange(selected, arr, objArr) {
      objArr.splice(0);
      for (let index = 0; index < selected.length; index++) {
        var e = arr.find((a) => a.uid === selected[index]);
        Vue.set(objArr, index, e);
      }
    },
  },
  computed: {
    hasEmptyBillingTier() {
      return this.task_type.billing_tiers.some(
        (tier, index) =>
          this.$v.task_type.billing_tiers.$each[index].price.$invalid
      );
    },

    user() {
      return this.$store.getters.user;
    },
    user_uid() {
      return this.$store.getters.user.uid;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
    uid() {
      return this.obj?.uid;
    },
    taskTypePricing() {
      return state.taskTypePricing;
    },
    departments() {
      if (state.departments) {
        return state.departments.filter((d) => d.active === 1);
      }
      return [];
    },
    billingTiers() {
      return state.billingTiers;
    },
  },
  watch: {},
};
</script>